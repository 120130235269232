import { lazy, Suspense, useState} from 'react';
import './CyberSensation.css'
import circle from '../../img/circle.png';

const Spline = lazy(() => import('@splinetool/react-spline'));

const CyberSecsation = () => {
    const [isSplineLoading, setIsSplineLoading] = useState(true);

    const handleSplineLoad = () => {
        setIsSplineLoading(false); // Меняем состояние, чтобы скрыть индикатор загрузки
    }

    return(
        <div id='cyber' className='cyber'>
            <div className='cyber__container'>
                <div className='cyber__overlay-container'>
            
                    {isSplineLoading && (<img className='services__circle-decor' alt='circle' src={circle} loading="lazy"/>)}
                    
                    {<Suspense fallback={<p className="loading-indicator">Загрузка 3D-модели...</p>}>
                        <Spline scene="https://prod.spline.design/YT66QEjcOWnxLHCN/scene.splinecode" onLoad={handleSplineLoad} 
                        style={{ display: 'flex', justifyContent: 'center'}} />
                    </Suspense>}

                </div>
                <div className='cyber__about'>
                <p className='cyber__title text-big'>ДИДЖИТАЛ СЕЛФИ</p>
                    <p className='cyber__subtitle text-medium'>Сделай или загрузи свое фото в чат-бот.<br></br> Узнай, как будешь выглядеть через сто лет. Получи селфи из будущего!</p>
                    <button className='cyber__button' onClick={() => {
                        window.open('https://t.me/MagnumCyber_bot');
                    }}>ПОПРОБОВАТЬ</button>
                </div>

                {/*<div className=''>
                    <video  
                            class="video-object__asset" 
                            loop={true} 
                            autoPlay={true}
                            muted
                            playsinline
                            src={background_video}
                           poster="https://nurfestival.com/wp-content/themes/hello-elementor/theme-img/video_holder_hero.jpg" 
                                                >
                    </video>            
                </div>*/}
            </div>
        </div>
    )
}

export {CyberSecsation}