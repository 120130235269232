import './Services.css';
import circle from '../../img/circle.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useCallback,  useMemo} from 'react';


function Services() {
  const navigate = useNavigate();

  useEffect(() => {
    const root = document.documentElement;
    const cursor = document.getElementById('custom-cursor');

    const handleMouseMove = (event) => {
      root.style.setProperty('--cursor-x', `${event.clientX}px`);
      root.style.setProperty('--cursor-y', `${event.clientY}px`);
    };

    const handleMouseEnter = (event) => {
      root.style.setProperty('--cursor-x', `${event.clientX}px`);
      root.style.setProperty('--cursor-y', `${event.clientY}px`);
      cursor.style.transform = 'translate(-50%, -50%) scale(1)';
    };

    const handleMouseLeave = () => {
      cursor.style.transform = 'translate(-50%, -50%) scale(0)';
    };

    window.addEventListener('mousemove', handleMouseMove);

    document.querySelectorAll('.services__item').forEach(item => {
      item.addEventListener('mouseenter', handleMouseEnter);
      item.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);

      document.querySelectorAll('.services__item').forEach(item => {
        item.removeEventListener('mouseenter', handleMouseEnter);
        item.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  const handleServiceRedirect = useCallback((area) => {
    switch (area) {
      case 'РЕКЛАМА':
        navigate(`/add`);
        break;
      case 'MOTION':
        navigate(`/motion`);
        break;
      case 'AVATAR':
        navigate(`/avatars`);
        break;
      case 'METAVERSE':
        navigate(`/metaverse`);
        break;
      case 'AR/VR/XR':
        navigate(`/ar-xr-vr`);
        break;
      case 'НЕЙРОСЕТИ':
        navigate(`/ai`);
        break;
      default:
        console.log('Unknown service area:', area);
    }
  }, [navigate]);;

  

  const servicesData = useMemo(() => ({
    add : [
      "Создание графики",
      "Тизеры и трейлеры",
      "VFX контент для соцсетей",
      "Разработка концепции"
    ], 
  
    motion : [
      "Эфирная графика для ТВ",
      "Медиадизайн для инсталляций",
      "Контент для концертов и event",
      "Интерактивный контент для expo",
    ],
  
    avatars : [
      "Digital ведущие",
      "Маскоты",
      "Игровые персонажи",
      "Виртуальные инфлюенсеры",
    ],
  
    metaverse : [
      "Roblox",
      "webGL",
      "Decentraland",
      "Spatial",
    ],
  
    ar_xr_vr : [
      "Разработка приложений и web",
      "Интерактивный AR и VR",
      "Контент для XR студий",
      "Разработка ПО для трекинга",
    ],
    
    ai : [
      "Чат-боты на основе ИИ",
      "Генерация ИИ видео",
      "ПО с нейронными сетями",
      "создание анимаций через ИИ",
    ],
    }), []);

  return (
    <div id='services' className="services">
        <p className='services__title text-big'>МЫ СОЗДАЕМ</p>
        <div className='services__container'>
            <div onClick={() => handleServiceRedirect('РЕКЛАМА')} className='services__item services__item1'><p className='services__item-title'>РЕКЛАМА</p>
              <div className='services__item-overlay'>
              <img className="services__item-background" alt={servicesData.add[0]} src="ServicesAssets/Services_background.png" />
                <ul className='services__list'>
                  {servicesData.add.map((item, index) => <li key={index} className='services__list-item'>{item}</li>)}
                </ul>
              </div>
            </div>
            <div onClick={() => handleServiceRedirect('MOTION')} className='services__item services__item2'><p className='services__item-title'>MOTION</p>
              <div className='services__item-overlay'>
              <img className="services__item-background" alt={servicesData.motion[0]} src="ServicesAssets/Services_background.png" />
                <ul className='services__list'>
                  {servicesData.motion.map((item, index) => <li key={index} className='services__list-item'>{item}</li>)}
                </ul>
              </div>
            </div>
            <div onClick={() => handleServiceRedirect('AVATAR')} className='services__item services__item3'><p className='services__item-title'>АВАТАРЫ</p>
            <div className='services__item-overlay'>
            <img className="services__item-background" alt={servicesData.avatars[0]} src="ServicesAssets/Services_background.png" />
                <ul className='services__list'>
                {servicesData.avatars.map((item, index) => <li key={index} className='services__list-item'>{item}</li>)}
                </ul>
              </div>
            </div>
            <div onClick={() => handleServiceRedirect('METAVERSE')} className='services__item services__item4'><p className='services__item-title'>METAVERSE</p>
              <div className='services__item-overlay'>
              <img className="services__item-background" alt={servicesData.metaverse[0]} src="ServicesAssets/Services_background.png" />
                <ul className='services__list'>
                {servicesData.metaverse.map((item, index) => <li key={index} className='services__list-item'>{item}</li>)}
                </ul>
              </div>
            </div>
            <div onClick={() => handleServiceRedirect('AR/VR/XR')} className='services__item services__item5'><p className='services__item-title'>AR/VR/XR</p>
              <div className='services__item-overlay'>
              <img className="services__item-background" alt={servicesData.ar_xr_vr[0]} src="ServicesAssets/Services_background.png" />
                <ul className='services__list'>
                {servicesData.ar_xr_vr.map((item, index) => <li key={index} className='services__list-item'>{item}</li>)}
                </ul>
              </div>
            </div>
            <div onClick={() => handleServiceRedirect('НЕЙРОСЕТИ')} className='services__item services__item6'><p className='services__item-title'>НЕЙРОСЕТИ</p>
              <div className='services__item-overlay'>
              <img className="services__item-background" alt={servicesData.ai[0]} src="ServicesAssets/Services_background.png" />
                <ul className='services__list'>
                {servicesData.ai.map((item, index) => <li key={index} className='services__list-item'>{item}</li>)}
                </ul>
              </div>
            </div>
            
        </div>
        <div id="custom-cursor"> 
        </div>
      </div>
    );
  }
  
  export default Services;
  