import './Text.css'
//import magnumTitle from '../../img/magnum-logo.png'
//import reel from '../../videos/reeel92mb.mp4'
// import reel from '../../videos/reel 1m16s new.mp4'
//import thumbnail from '../../img/reel-thumbnail-min.png'


function Text() {
    return (
        <main className='text_main'>
            <div>
                <p className='text__subtitle text-medium'>Доблестные ребята, набившие руку <br />в экспериментальных мультимедиа технологиях. Соединяем передовые коммуникационные решения <br />и задачи бизнеса
                </p>
            </div>
        </main>
    );
}

export default Text;