import { lazy, Suspense, useState} from 'react';
import './ChatBot.css'
import Prezent from '../../img/prezent.webp'; 
import circle from '../../img/circle.png';



const ChatBot = () => {
    return(
        <div id='bot' className='chatbot'>
            <div className='chatbot__container'>
                <div className='chatbot__overlay-container'>
                    <img className="chatbot__image" onClick={() => {
                        window.open('https://t.me/MagnumParserBot');
                    }}  src={Prezent} loading="lazy" />
                    <img className="chatbot__image2" src={circle} loading="lazy"/>
                </div>
                <div className='chatbot__about'>
                    <p className='chatbot__title'>Получи подарок — <br></br>умный чат-бот для поиска заказов!</p>
                    <p className='chatbot__subtitle'>Твой личный помощник, который мониторит Telegram и ВКонтакте 24/7, чтобы находить заказы по твоим ключевым словам и получать о них уведомления. Это бесплатно, просто<br></br> и эффективно!</p>
                    <button className='chatbot__button' onClick={() => {
                        window.open('https://t.me/MagnumParserBot');
                    }}>ЗАБРАТЬ  БОТА</button>
                </div>
            </div>
        </div>
    )
}

export {ChatBot}